import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from "framer-motion";
import { Container, Form, Button } from 'react-bootstrap'

import {socket, socketFunctions} from '../socket.js';

import '../assets/CreateGameForm.css';

const CreateGameForm = () => {
  
  const navigate = useNavigate();

  const [name, setName] = useState('');
  const [gameID, setGameID] = useState('');
  const [gameTypes, setGameTypes] = useState([]);
  const [selectedGameType, setSelectedGameType] = useState('');
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const flashError = (errormessage) => {
    setErrorMessage(errormessage);
    setShowErrorMessage(true);
    setTimeout(() => { setShowErrorMessage(false); }, 2500);
  }

  // Handle receiving the response for create room and join room
  useEffect(() => {
    !socketFunctions.isConnected() && socketFunctions.connect();

    socket.on('createRoomResponse', (response) => {
      if (response.success) {
        const roomID = response.gameID;
        const gametype = response.gametype;
        navigate(`/${gametype}/creator/${roomID}`, { state: name });
      } else {
        console.error(response.error);
      }
    });

    socket.on('joinRoomResponse', (response) => {
      if (response.success) {
        const roomID = response.gameID;
        navigate(`/waiting/${roomID}`, { state: name });
      } else {
        flashError('Game room does not exist.');
        console.error(response.error);
      }
    });

    socket.on('getGameTypesResponse', (response) => {
      let gametypes = null;
      if (response.success) {
        gametypes = JSON.parse(response.gameTypes);
      }
      if (gametypes === null){
       gametypes = [{"game_count": 0, "id": 1, "name": "Ahole"},
        {"game_count": 0,"id": 2,"name": "Chkobba"}]
      }
      setGameTypes(gametypes);
    });

    return () => {
      socket.off('createRoomResponse');
      socket.off('joinRoomResponse');
      socket.off('getGameTypesResponse');
    };
  });

  useEffect(() => {
    socket.emit('getGameTypes');
    setTimeout(() => { if(!socketFunctions.isConnected()){flashError('Websocket is not available. Try to refresh the page.');console.error('Websocket is not available. Try to refresh the page.');} }, 2500);
  }, [selectedGameType]);

  const GameTitle = () => {
    return (
      <motion.div
        className="create-game-form-title"
        initial={{ scale: 0 }}
        animate={{ rotate: 360, scale: 1 }}
        transition={{ type: "spring", stiffness: 200, damping: 17 }}
      >
        LUSORICTO!
      </motion.div>
    )
  }

  // Handle the response for creating a room
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (name.trim() === '') {
      flashError('Please enter a valid name.');
      return;
    }

    socket.emit('createGameRoom', ({ gametype: selectedGameType}), (response) => {
      if (response !== undefined && response.success) {
        const roomID = response.gameID;
        navigate(`/waiting/${roomID}`, { state: name });
      } else {
        flashError('Failed to create a game room.');
        response !== undefined && console.error(response.error);
      }
    });
  };

  const handleGameCode = async (event) => {
    event.preventDefault();
    if (name.trim() === '' && gameID.trim() === '') {
      flashError('Please enter a valid name/game code.');
      return;
    }
    socket.emit('joinGameRoom', ({ gameID: gameID }), (response) => {
      if (response !== undefined && response.success) {
        navigate(`/waiting/${gameID}`, { state: name });
      } else {
        flashError('Game code does not exist.');
        console.error(response && response.error);
      }
    })
  };

  const handleJoinRandom = async (event) => {
    event.preventDefault();
    if (name.trim() === '') {
      flashError('Please enter a valid name');
      return;
    }
    socket.emit('joinRandomGameRoom', (response) => {
      if (response !== undefined && response.success) {
        navigate(`/waiting/${gameID}`, { state: name });
      } else {
        flashError('There is no joinable game');
        console.error(response && response.error);
      }
    })
  }

  return (
    <Container className="create-game-form-container vh-100 d-flex align-items-center justify-content-center">
      {GameTitle()}
      <motion.div
        initial={{ opacity: 0, scale: 1 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5 }}
      > 
        <Form onSubmit={handleSubmit} style={{ maxWidth: '200px' }}>
          <Form.Group className="mb-2 text-left">
            <Form.Label className="create-game-form-label float-start">
              Player Name:
            </Form.Label>
            <Form.Control type="text" value={name} onChange={(event) => setName(event.target.value)} className="create-game-form-input" id="name" placeholder="e.g., John" />
          </Form.Group>
          <Form.Group controlId="selectGame">
            <Form.Label className="create-game-form-label float-start">Select a Game:</Form.Label>
            <Form.Control as="select" value={selectedGameType} onChange={(event) => setSelectedGameType(event.target.value)} className="create-game-form-input">
                <option value="">Select a game...</option>
                {gameTypes.map(item => (
                <option key={item.id} value={item.name}>
                    {item.name}
                </option>
                ))}
            </Form.Control>
          </Form.Group>
          <Button className="mb-3 mb-sm-6 create-game-form-button" type="submit">
            CREATE GAME
          </Button>
        </Form>
        <Form onSubmit={handleGameCode} style={{ maxWidth: '200px' }}>
          <Form.Group className="mb-2 mb-sm-5 text-left">
            <Form.Label className="create-game-form-label float-start">
              Game Code:
            </Form.Label>
            <Form.Control type="text" value={gameID} onChange={(event) => setGameID(event.target.value)} className="create-game-form-input" id="gameID" placeholder="e.g., zdh3fj" />
          </Form.Group>
          <Button className="mb-3 btn join-game-form-button" type="submit">
            JOIN VIA CODE
          </Button>
        </Form>
        <Button className="mb-3 btn join-game-form-button" type="submit" onClick={handleJoinRandom}>
            JOIN RANDOM GAME
        </Button>
        {showErrorMessage && (<div className="notification-alert notification-alert--error">{errorMessage}</div>)}
      </motion.div>
    </Container>
  );
}

export default CreateGameForm;