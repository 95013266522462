import React from 'react';
import '../../assets/CardGameBoard.css';
import AccordionMenu from '../subcomponent/AccordionMenu';

const AholeGameRules = () => {
    const accordionItems = [
      {
        title: 'Card Distribution',
        content: `All cards are dealt to the players at the start. The first player to the right of the dealer may play either a single card or pairs (multiple cards of the same value). 
        Subsequent players must play the same number of cards but of a higher value. Players can always pass. The person with the highest card wins the trick and starts again with their turn.
        Once a player has played all their cards, they become the King (or President, Boss, Chief). The second player becomes the Vice King (or Vice President, Vice Boss, Vice Chief). 
        The last player is called the Asshole. The second to last player becomes the Vice Asshole. All other players in between are referred to as Citizens, Farmers, or Merchants.`
      },
      {
        title: 'Card Transfers',
        content: `Players in lower ranks must give their highest cards to higher ranks, and vice versa:<br>
          - The Asshole gives their two highest cards to the King.<br>
          - The King gives two of their cards to the Asshole.<br>
          - The Vice Asshole gives their highest card to the Vice King.<br>
          - The Vice King gives one of their cards to the Vice Asshole.`,
      }
    ];
    
    return (
        <AccordionMenu accordionItems={accordionItems} />
    );
};

export default AholeGameRules;
