import React, { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Container, Form, Button, ListGroup } from 'react-bootstrap';

import '../../assets/WaitingPage.scss';
import {socket} from '../../socket.js';

const AholeCreatorRoom = () => {
  const navigate = useNavigate();
  const { roomID } = useParams();
  const [showCopyMessage, setShowCopyMessage] = useState(false);
  const [numPlayers, setNumPlayers] = useState(3);
  const [numCards, setNumCards] = useState(36);
  const [joinedPlayers, setJoinedPlayers] = useState([]);
  const location = useLocation();
  const timeoutRef = useRef(null);
  const name = location.state;

  useEffect(() => {
    socket.emit('onPlayerReady', { name: name });
    socket.on('startGameSession', () => { navigate(`/ahole/game/${roomID}`); });
    socket.on('getGamePlayersResponse', (response) => { 
      if (response.success) {
        const players = JSON.parse(response.players);
        setJoinedPlayers(players);
        numPlayers < players.length && setNumPlayers(players.length);
      }
    });

    // Function to fetch the list of players
    const fetchPlayers = async () => {
      socket.emit('getGamePlayers');
    };

    // Fetch players initially
    fetchPlayers();

    // Set interval to fetch players every 2 seconds
    const intervalId = setInterval(fetchPlayers, 2000);

    return () => {
      clearInterval(intervalId);
      socket.off('startGameSession');
    };
  }, [navigate, roomID, name]);

  const handleCopy = () => {
    if (timeoutRef.current !== null) {
      clearTimeout(timeoutRef.current);
    }
    setShowCopyMessage(true);
    setTimeout(() => { setShowCopyMessage(false); }, 2500);
  };

  const handleStartGame = () => {
    socket.emit('startGame', { roomID, numCards, numPlayers });
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      <Container className="waiting-page-container vh-100 vw-100 d-flex flex-column align-items-center justify-content-center">
        <h2 className="mt-md-3 ms-2 me-2 mb-2">WAITING FOR OTHER PLAYER...</h2>
        <div className="copy-text">
          <div className="text">Game Code: {roomID}</div>
          <CopyToClipboard text={`${roomID}`} onCopy={handleCopy}>
            <button><i className="fa fa-clone"></i></button>
          </CopyToClipboard>
        </div>
        <p className="mt-4 mt-md-3 ms-2 me-2">Share the above code with your friends so they can join your game!</p>
        {showCopyMessage && (<div className="notification-alert notification-alert--success"> Game Code copied!</div>)}
        
        <Form className="mt-4">
          <Form.Group controlId="numCards">
            <Form.Label>Number of Cards</Form.Label>
            <Form.Control
              type="number"
              value={numCards}
              onChange={(e) => setNumCards(e.target.value)}
              min="36"
              step="4"
              max="52"
            />
          </Form.Group>
          <Form.Group controlId="numPlayers">
            <Form.Label>Number of Players</Form.Label>
            <Form.Control
              type="number"
              value={numPlayers}
              onChange={(e) => setNumPlayers(e.target.value)}
              min="3"
              max="8"
            />
          </Form.Group>
          <Button variant="primary" onClick={handleStartGame} className="mt-3">Start Game</Button>
        </Form>

        <ListGroup className="mt-4">
          <h5>Joined Players:</h5>
          {joinedPlayers && joinedPlayers.map((player) => (
            <ListGroup.Item>{player}</ListGroup.Item>
          ))}
        </ListGroup>
      </Container>
    </motion.div>
  );
};

export default AholeCreatorRoom;
