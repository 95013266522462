import io from 'socket.io-client';

const hostname = window.location.hostname;
const port = process.env.PORT || 8004;

const socket = io.connect(`https://${hostname}`);

const socketFunctions = {
  connect: () => {
    socket.connect();
  },
  disconnect: () => {
    socket.disconnect();
  },
  emitEvent: (eventName, data) => {
    socket.emit(eventName, data);
  },
  onEvent: (eventName, callback) => {
    socket.on(eventName, callback);
  },
  offEvent: (eventName) => {
    socket.off(eventName);
  },
  getId: () => {
    return socket.id;
  },
  isConnected: () => {
    return socket.connected;
  }
};

export { socket, socketFunctions };