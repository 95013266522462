import Container from 'react-bootstrap/Container';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import CreateGameForm from './components/CreateGameForm';
import AholeGameBoard from './components/ahole/AholeGameBoard';
import WaitingPage from './components/WaitingPage';
import AholeCreatorRoom from './components/ahole/AholeCreatorRoom';
import ChkobbaGameBoard from './components/chkobba/ChkobbaGameBoard';
import ChkobbaCreatorRoom from './components/chkobba/ChkobbaCreatorRoom';

export default function App() {
  return (
    <Container fluid className="App">
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={< CreateGameForm />} />
          <Route path="/waiting/:roomID" element={< WaitingPage />} />
          <Route path="/ahole/creator/:roomID" element={< AholeCreatorRoom />} />
          <Route path="/ahole/game/:roomID" element={< AholeGameBoard />} />
          <Route path="/chkobba/creator/:roomID" element={< ChkobbaCreatorRoom />} />
          <Route path="/chkobba/game/:roomID" element={< ChkobbaGameBoard />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter>
    </Container>
  );
}
