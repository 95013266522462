import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const MessageBox = ({ message, onClose }) => {
    const messageLines = message.split('\n').map((line, index) => (
        <div key={index}>{line}</div>
    ));

    return (
        <Modal show={!!message} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>Message</Modal.Title>
            </Modal.Header>
            <Modal.Body>{messageLines}</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default MessageBox;