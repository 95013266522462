import React from 'react';
import { Row, Col } from 'react-bootstrap'
import { useParams } from 'react-router-dom';
import { Score, CardGroup } from '../Layout.js';

import Card from './Card';

const PlayerComponent = ({ playerName, playerCards, isPlayer, isTurn = false, selectedCards, onCardSelect = () => {} }) => {
    const { roomID } = useParams();

    return (
        <Row className="align-items-center p-4 mt-4">
            <Col> 
                <Score>
                    <div>{playerName}</div>
                </Score>
            </Col>
            <Col xs={12} sm={9}>
                <CardGroup className="p-4">
                    <Row>
                        {playerCards && playerCards.map((data, index) => {
                            const isSelected = selectedCards ? selectedCards.some(selectedCard => selectedCard.code === data.code) : false;

                            return (
                                <Col key={`card-column-${index}`}>
                                    <Card
                                        cardID={isPlayer ? `player-card-${index + 1}` : `opponent-card-${index + 1}`}
                                        cardData={isPlayer ? data : data && { image: 'https://deckofcardsapi.com/static/img/back.png', isVisible: true }}
                                        onClick={isPlayer && isTurn ? () => onCardSelect(data) : () => {}}
                                        enableHover={isPlayer && isTurn}
                                        isSelected={isSelected}
                                    />
                                </Col>
                            );
                        })}
                    </Row>
                </CardGroup>
            </Col>
        </Row>
    );
};

export default PlayerComponent;