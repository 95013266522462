import React from 'react';
import { useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { Row, Column } from '../Layout.js';

import '../../assets/GameOver.css';

const GameOver = ({winners, winningpoints, loosingpoints}) => {
    const navigate = useNavigate();


  return (
        <AnimatePresence>
            <motion.div
                className='background'
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.5 }}
                exit={{ opacity: 0, scale: 0, duration: 5 }}
            >
                <Column>
                    <Row>
                        <motion.div
                            style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            }}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                        >
                            <Column>
                                <Row></Row>
                                <Row className='g-3'>
                                    <motion.p
                                        className='game-title'
                                        initial={{ y: 200 }}
                                        animate={{ y: -20 }}
                                        transition={{ type: 'spring', bounce: 0.5, delay: 2 }}
                                    >
                                        GAME ENDED
                                    </motion.p>
                                </Row>
                                    <motion.p
                                        className='game-title'
                                        initial={{ opacity: 0, y: -50 }}
                                        animate={{ opacity: 1, y: -50 }}
                                        transition={{ type: 'spring', bounce: 0.5, delay: 2 }}
                                    >
                                        {winners} WON!
                                    </motion.p>
                                <Row>
                                    <Column><p>The Winner Team had: {winningpoints}</p></Column>
                                    <Column></Column>
                                    <Column></Column>
                                    <Column><p>The Looser Team had: {loosingpoints}</p></Column>
                                </Row>
                                <Row>
                                    <motion.button
                                        className='end-game-button'
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }} 
                                        transition={{ duration: 1, delay: 2 }}
                                        onClick={() => {navigate(`/`)}}
                                    >
                                        RETURN TO HOMEPAGE
                                    </motion.button>
                                </Row>
                            </Column>
                        </motion.div>
                    </Row>
                </Column>
            </motion.div>
        </AnimatePresence>
  );
};

export default GameOver;
