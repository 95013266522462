import React from 'react';
import '../../assets/CardGameBoard.css';
import AccordionMenu from '../subcomponent/AccordionMenu';

const ChkobbaGameRules = () => {
    const accordionItems = [
        {
          title: 'Card Distribution',
          content: `One of the two players shuffles the cards and presents the deck to their opponent, who cuts the deck and draws a card. The opponent can keep the card or place it on the table (usually a simple table). 
            Then, the first player begins the distribution based on the decision of the drawer. If the drawer keeps the first card, the dealer gives them two more cards, then deals three to themselves, and ends by placing four cards on the table. 
            If the drawer places the first card on the table, the dealer adds three cards, then deals three cards to their opponent and three to themselves. The game thus begins with four cards on the table and three in each player’s hand.
            After the distribution, if three cards of the same value are on the table, the distribution must be redone. This prevents these cards from being unplayable and remaining on the table from the beginning to the end.
            The first round begins with the drawer playing the first card. When there are no more cards in the players' hands, the dealer gives three cards to each and the next round begins. There are six rounds per hand. 
            At the end of each hand, the roles of drawer and dealer are exchanged.`
        },
        {
          title: 'Gameplay',
          content: `There are two ways to win a trick (أكل ʾakala or "eat" in Arabic):<br>
            - You can "eat" a card of the same value as one of yours. Both cards are then kept face down in a pile (Select a card in the board and one in the player Cards. Use the button "Play").<br>
            - You can also "eat" by addition: if a player has a queen and there is a 7 and an ace on the table, they can take all three and place them face down in their pile. An addition can only be made if the player has the exact sum in hand and the terms of the addition are on the table. Thus, if the player has a 7 and an ace, they cannot play them together to take a queen.<br>
            - If you hold a card that allows both options, taking the card of the same value takes priority over addition. Thus, a player cannot use their queen to take a 7 and an ace if there is another queen on the table.<br>
            If you cannot "eat" a card, you must place one on the table, as you cannot skip your turn (Select a card from the player cards, and press Next.). If you have the option to win the trick with one of your cards, it is not mandatory to play it first. You can play other cards that do not allow you to win the trick and save your card for later, depending on your strategy. Finally, if there are still cards on the table at the end of the hand, the player who won the last trick takes them.`,
        },
        {
          title: 'Scoring Points',
          content: `The goal of the game is to be the first to score a minimum of 11, 21, or 31 points, with players deciding in advance how many points they wish to reach. There are two types of points: the four final points, 
            which are counted at the end of each hand, and chkobba points, which can be earned during the course of each hand.`,
        },
        {
          title: 'Final Points',
          content: `The four final points are named kārṭa كارطة, dīnārī ديناري, barmīla برميلة, and sabʿa l-ḥayya سبعة الحيّة. They are awarded to players at the end of each hand according to their own rules:<br>
            - <strong>Kārṭa</strong> awards a point to the player with the most cards in their pile. If there is a tie, no one scores.<br>
            - <strong>Dīnārī</strong> awards a point to the player with the most diamonds in their pile. If there is a tie, no one scores.<br>
            - <strong>Barmīla</strong> (from the Italian primiera) awards a point to the player with the most 7s in their pile. If there is a tie, the number of 6s is counted. If there is still a tie, no one scores.<br>
            - <strong>Sabʿa l-ḥayya</strong> awards a point to the player who possesses the 7 of diamonds. Except for chkobba points, this last card is the only one that scores a point by itself, which adds a psychological aspect to its capture. Possessing three 7s, including the 7 of diamonds, thus grants two points (sabʿa l-ḥayya and barmīla) to their holder.`,
        },
        {
            title: 'Chkobba Points',
            content: `Chkobba points are exceptional points scored during the game according to a simple rule: each time a player completely empties the table by taking one or more cards, they score a point. No chkobba can be scored at the end of the last round of a hand.`,
        },
        {
          title: 'Warning',
          content: `The Game is still a prototype. If something seems off, please use the Button "Refresh" at the bottom of the page.`,
        },
      ];

    return (
        <AccordionMenu accordionItems={accordionItems} />
    );
};

export default ChkobbaGameRules;