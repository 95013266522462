import React from 'react';
import { Row, Col } from 'react-bootstrap'
import { Score, CardGroup } from '../Layout.js';

import Card from './Card';

const BoardComponent = ({ title, boardCards, showCards, selectedCards, onCardSelect = () => {} }) => {
    return (
        <Row className="align-items-center p-4 mt-4">
            <Col> 
                <Score>
                    <div>{title}</div>
                </Score>
            </Col>
            <Col xs={12} sm={9}>
                <CardGroup className="p-4">
                    <Row>
                        {boardCards && boardCards.map((data, index) => {
                            const isSelected = selectedCards ? selectedCards.some(selectedCard => selectedCard.code === data.code) : false;

                            return (
                                <Col key={`card-column-${index}`}>
                                    <Card
                                        cardID={`board-card-${index + 1}`}
                                        cardData={showCards ? data : data && { image: 'https://deckofcardsapi.com/static/img/back.png', isVisible: true }}
                                        onClick={() => onCardSelect(data)}
                                        enableHover={true}
                                        isSelected={isSelected}
                                    />
                                </Col>
                            );
                        })}
                    </Row>
                </CardGroup>
            </Col>
        </Row>
    );
};

export default BoardComponent;