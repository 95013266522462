import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useParams } from 'react-router-dom';

import { Layout } from '../Layout.js';
import { Container, Row, Col, Button } from 'react-bootstrap'
import '../../assets/CardGameBoard.css';

import PlayerComponent from '../subcomponent/PlayerComponent';
import BoardComponent from '../subcomponent/BoardComponent';
import TurnStatus from '../subcomponent/TurnStatus';
import MessageBox from '../subcomponent/MessageBox';
import {socket} from '../../socket.js';
import AholeRulesPage from './AholeRulesPage.js'

const AholeGameBoard = () => {

    const { roomID } = useParams();

    let isMounted = false;
    const location = useLocation();
    
    const [gameState, setGameState] = useState([]);
    const [playerName, setPlayerName] = useState('');
    const [playerCards, setPlayerCards] = useState([]);
    const [lastPlayedCards, setLastPlayedCards] = useState([])

    const [players, setPlayers] = useState([]);
    const [nextPlayer, setNextPlayer] = useState("");
    const [nextPlayerName, setNextPlayerName] = useState("");
    const [turnStatus, setTurnStatus] = useState(false);
    const [selectedCards, setSelectedCards] = useState([]);
    const [modalMessage, setModalMessage] = useState('');
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const flashError = (errormessage) => {
        setErrorMessage(errormessage);
        setShowErrorMessage(true);
        setTimeout(() => { setShowErrorMessage(false); }, 2500);
    }

    useEffect(() => {
        const handleBeforeUnload = (event) => {
          event.preventDefault();
          event.returnValue = ''; // Show confirmation dialog
        };
    
        const handleRouteChange = (event) => {
          const confirmLeave = window.confirm('Are you sure you want to leave this page?');
          if (!confirmLeave) {
            event.preventDefault(); // Prevent navigation
          }
        };

        const handleNavigationAway = () => {
            socket.disconnect();
          };
    
        window.addEventListener('beforeunload', handleBeforeUnload);
        window.addEventListener('popstate', handleRouteChange); // For back/forward navigation
    
        return () => {
          window.removeEventListener('beforeunload', handleBeforeUnload);
          window.removeEventListener('popstate', handleRouteChange);
          if (isMounted) {
            handleNavigationAway();
          }
          isMounted = true;
        };
      }, [location]);

    const convertCardData = (card) => {
        const value = card['code'].charAt(0);
        const suit = card['code'].charAt(1);
        const valueCode = card['game_value']
    
        return {
            image: `https://deckofcardsapi.com/static/img/${card['code']}.png`,
            value: valueCode,
            suit: suit,
            code: card['code'],
            game_value: card['game_value'],
            isVisible: true
        };
    };

    useEffect(() => {
        socket.on('getGameStateResponse', (response) => {
            if (response.success) {
                const gamestate = JSON.parse(response.gameState);
                const nxtPlyr = response.nextPlayer;
                var lastMove = gamestate.last_move ? JSON.parse(gamestate.last_move.player_cards) : null;
                var lastCards = lastMove ? lastMove.cards : [];
                var cards = lastCards ? lastCards.map(convertCardData) : [];
                setNextPlayerName(gamestate.last_move ? gamestate.last_move.player.name : "");
                setGameState(gamestate);
                setPlayers(gamestate.players);
                setNextPlayer(nxtPlyr);
                cards && setLastPlayedCards(cards);
                nxtPlyr === socket.id ? setTurnStatus(true) : setTurnStatus(false);
                gamestate.last_move?.is_finisher && nxtPlyr === socket.id && flashError('You inherit the turn and can begin from new');
            }
        });

        socket.on('getPlayerStateResponse', (response) => {
            if (response.success) {
                const playerstate = JSON.parse(response.playerState);
                var playerCards = JSON.parse(playerstate.hand);
                const pC = playerCards.cards.map(convertCardData);
                setPlayerCards(pC);
                setPlayerName(playerstate.name);
            }
        })

        socket.on('onSkipTurnResponse', (response) => { 
            if (response.success) {
                setNextPlayer(response.nextPlayer);
                response.nextPlayer === socket.id ? setTurnStatus(true) : setTurnStatus(false);
                socket.emit('getGameState', { gameID: roomID });
            }
        });

        socket.on('onPlayCardsResponse', (response) => { 
            if (response.success) {
            }else{
                flashError(response.errormessage);
            }
        });

        socket.on('gameEndResponse', (response) => { 
            if (response.success) {
                const gamestate = JSON.parse(response.gameState);
                setGameState(gamestate);
                var message = gamestate.players.map(player => `${player.name}: ${player.rank || 'No status'}`).join(`\n`);
                message = `This Round is finished. Here the ranking:\n\n` + message;
                setModalMessage(message);
                setLastPlayedCards([]);
                setNextPlayerName('');
            }
            socket.emit('getPlayerState');
            socket.emit('getGameState', { gameID: roomID });
        });

        socket.on('onDisconnectResponse', (response) => { 
            if (response.success) {
                const message = response.playername + ' has left the game.'
                setModalMessage(message);
            }
        });

        socket.emit('getGameState', { gameID: roomID });
        socket.emit('getPlayerState');
        
        return () => {
          socket.off('getGameStateResponse');
          socket.off('getPlayerStateResponse');
          socket.off('getWinningStateResponse');
          socket.off('onSkipTurnResponse');
          socket.off('gameEndResponse');
          socket.off('onDisconnectResponse');
        };
    }, [roomID]);

    const refreshStates = async (event) => {
        socket.emit('getGameState', { gameID: roomID, nextPlayer: nextPlayer });
        socket.emit('getPlayerState');
        setSelectedCards([]);
    };

    const onClickPlay = () => {
        setSelectedCards([]);
        const data = { player_cards: selectedCards, gameID: roomID }
        socket.emit('onPlayCards', data);
    };

    const onClickSkip = () => {
        const data = { gameID: roomID }
        socket.emit('onSkipTurn', data);
    };

    const onClickOrderCards = () => {
        const sortedCards = playerCards.slice().sort((a, b) => {
            const valueA = typeof a.value === 'string' ? parseInt(a.value, 10) : a.value;
            const valueB = typeof b.value === 'string' ? parseInt(b.value, 10) : b.value;
            
            return valueA - valueB;
        });
        setPlayerCards(sortedCards);
    };

    const handleCardSelection = (card) => {
        setSelectedCards((prevSelectedCards) => {
            if(prevSelectedCards.length > 0 && prevSelectedCards[0].value !== card.value){
                flashError("You can only select Cards of the same value");
                return [...prevSelectedCards]
            }
            const isSelected = prevSelectedCards.some(selectedCard => selectedCard.code === card.code);
            return (isSelected ? prevSelectedCards.filter(selectedCard => selectedCard.code !== card.code) : [...prevSelectedCards, card]);
        });
    };

    const closeMessage = () => {
        setModalMessage('');
        socket.emit('getPlayerState');
    }

    return (
        <Container fluid className='game-container'>
            <Layout className='game-content'>
                <motion.div
                    initial={{ opacity: 0, scale: 0 }}
                    animate={{ opacity: 1, scale: 1 }}
                    exit={{ opacity: 0, scale: 0 }}
                    transition={{ duration: 0.5 }}
                >
                    <AholeRulesPage />
                    <Row><h2>Other Players</h2>
                        <ul>
                            {players && players.map((player) => (
                                player.request_sid !== socket.id &&
                                <li key={player.id}>
                                    {player.name}: {player.card_count} card(s)
                                </li>
                            ))}
                        </ul>
                    </Row>
                    <BoardComponent title={`Last Played: ${nextPlayerName}`} showCards={true} boardCards={lastPlayedCards} />
                    {gameState.lastMove && <p>Last Move played by: {gameState.lastMove.player.name}</p>}
                    <Row className='align-items-center pt-4 mt-4'>
                        <Col>
                            <TurnStatus turnStatus={turnStatus}></TurnStatus>
                        </Col>
                    </Row>
                    <PlayerComponent playerName={playerName} playerCards={playerCards} isPlayer={true} isTurn={turnStatus} selectedCards={selectedCards} onCardSelect={handleCardSelection} />
                    {turnStatus &&
                        <Row>
                            <Button onClick={onClickOrderCards} variant="primary">Order Cards</Button>
                            <Button onClick={onClickPlay} variant="success">Play Selected</Button>
                            <Button onClick={onClickSkip} variant="danger">Skip</Button>
                        </Row>
                    }
            </motion.div>
            </Layout>
            <Button onClick={refreshStates}>Refresh</Button>
            <MessageBox message={modalMessage} onClose={closeMessage} />
            {showErrorMessage && (<div className="notification-alert notification-alert--error">{errorMessage}</div>)}
        </Container>
        );
}

export default AholeGameBoard;